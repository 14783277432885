@mixin nav-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;
  
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;
    
    #{$vertical}: 0; 
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  
  &:hover {
    color: $hover;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; }
    
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}


.navbar {
  display: flex;
  position: relative;
  margin-top: -15px;
  padding: 25px 0 10px 0;
  width: 100%;
  // height: 50px;
  font-size: 20px;
  //background: #ffffd4; // temp color until footer is finalized.
  background: lavender; 
  border-bottom: 5px solid rgb(54, 52, 52);
  @media (max-width: 655px) {
    display: none;
  }
  .bar {
    display: flex;
    width: 100%;
    margin-left: 50px;
    // background: lavenderblush;
    // padding: 10px 5px;
    margin-top: 50px;
    /* padding: 25px 5px 0 5px; */
    font-size: 25px;
    justify-content: space-evenly;
  
  }

  .navhome {
    position: fixed;
    margin-left: 15px;
    cursor: pointer;
    @media (max-width: 1450px) {
      position: relative;
    }
    @media (max-width: 655px) {
      margin-left: 5px;
    
    }
  }
  
  // #8191c5
  .navitem {
    @include nav-border-drawing(none, 
    #556b2f, 4px, top, left);
    background: none;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    // font: 700 1.2rem 'Roboto Slab', sans-serif;
    padding: 1em 1em;
    letter-spacing: 0.05rem;
    
    // &:focus { outline: 2px dotted #55d7dc; }
    // &:hover {
    //   background: white;
    //   color: black;
    //   border-bottom: 2px solid black;
    // }
  
  }
  
  
}
