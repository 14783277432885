
@mixin div-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
    box-shadow: inset 0 0 0 $width $color;
    color: $color;
    transition: color $duration $duration/3;
    position: relative;
    
    &::before,
    &::after {
      border: 0 solid transparent;
      box-sizing: border-box;
      content: '';
      pointer-events: none;
      position: absolute;
      width: 0; height: 0;
      
      #{$vertical}: 0; 
      #{$horizontal}: 0;
    }
  
    &::before {
      $h-side: if($horizontal == 'left', 'right', 'left');
      
      border-#{$vertical}-width: $width;
      border-#{$h-side}-width: $width;
    }
    
    &::after {
      $v-side: if($vertical == 'top', 'bottom', 'top');
      
      border-#{$v-side}-width: $width;
      border-#{$horizontal}-width: $width;
    }
    
    &:hover {
      color: $hover;
      
      &::before,
      &::after {
        border-color: $hover;
        transition: border-color 0s, width $duration, height $duration;
        width: 100%;
        height: 100%;
      }
      
      &::before { transition-delay: 0s, 0s, $duration; }
      
      &::after { transition-delay: 0s, $duration, 0s; }
    }
  }

.panelDiv{
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    padding: 1.7rem 2.5rem;
    @media (max-width: 655px) {
      padding: 1.7rem 1rem;
      justify-content: center;
  }
    // font-size: 2.4rem;
    line-height: 1.25;
    @include div-border-drawing(black, #8191c5, 8px, bottom, right);

}
//#b09dde

.titleMain{
    font-size: 2rem;
    font-weight: bold;

    @media (max-width: 760px) {
      margin-bottom: 10px;
  }
}

.descriptionMain{
    display: flex;
    justify-content: flex-start; 
    align-self: flex-start;
    color: black;
    font-size: 1.6rem;
    line-height: 1.5;
    text-align: left;
    margin: 20px auto 30px;
    max-width: 1000px;
    @media (max-width: 760px) {
      display: none;
    //   flex-direction: column;
    //   justify-content: center; 
    //  align-self: center; 
  }
}

.desc{
  display: flex;
  flex-direction: row;
}

// .descsub{
//   display: flex;
//   flex-direction: row;
// }

// .descsub2{
//   display: flex;
//   flex-direction: row;
// }

.avatar{
  height: 250px;
  width: 250px;
  background: white;
  position: relative;
  display: flex;
  border-radius: 50%;
  margin-right: 40px;
  flex: 0 0 203px;
  margin-top: -30px;
  align-self: center;
  @media (max-width: 760px) {
    display: none;
  //   flex-direction: column;
  //   justify-content: center; 
  //  align-self: center; 
} 
}

// .descriptionMobile {
//   @media (max-width: 655px) {
//     display: flex;
//     margin-right: inherit;
//     // padding-right: 2px;
//   }
// }

.descriptionMainMobile {
  display: none;
@media (max-width: 760px) {
  display: flex;
  justify-content: flex-start; 
  align-self: flex-start;
  color: black;
  font-size: 1.6rem;
  line-height: 1.5;
  margin: 20px 3px 30px 3px;
  max-width: 1000px;
  padding: 0 5px;
    flex-direction: column;
    justify-content: center; 
  align-self: center; 
}
}

.avatarMobile{
  display: none;
  @media (max-width: 760px) {
  height: 250px;
  width: 250px;
  background: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex: 0 0 203px;
  align-self: center;
  }
}
