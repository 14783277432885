
.donateContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #231f20;
    color: white;
    padding: 1.7rem 2.5rem;
    // padding: 25px 75px 0 75px;
    // margin-top: 1rem;
    border: 7px solid white;
    width: 100%;
    height: 100%;
    // position: absolute;
    // overflow: hidden;
    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 1.7rem 1rem;
    }
    @media (max-width: 500px) {
        margin-top: 10px;
    }
}

h2 {
    font-size: 2rem;
}

.donationInfoSection {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: -15px;
    margin-right: 10px;
    padding-right: 25px;
    @media (max-width: 1024px) {
        padding-right: 0;
        margin-right: inherit;
    }
}

.info {
    // text-align: justify;
    text-align: left;
    font-size: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 1rem;
    @media (max-width: 1024px) {
        padding-right: inherit;
        text-align: left;
    }
}

.miniInfo{
    text-align: center;
}

.imageAvatar {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 218px;
    align-self: center;
    // margin-left: 22.5%;
    width: 270px;
    background: white;
    // position: relative;
    border-radius: 50%;
    // margin-right: 40px;
    // flex: 0 0 203px;
    // margin-top: -30px;
    @media (max-width: 1024px) {
        align-self: center;
        margin-bottom: 1.5rem;
      }
}

.donationImageSection{
    margin: 1rem;
    min-width: 512px;
    // background: powderblue;
    width: 512px;
@media (max-width: 1024px) {
        align-self: center;
        justify-content: center;
        align-items: center;
        min-width: inherit;
        // width: inherit;
        padding: 1rem;
     }
 @media (min-width: 601px) and (max-width: 1024px) {
    width: 75%;
 }
 @media (max-width: 600px) {
    width: inherit;
 }
}

//   .description {
//     color: black;
//     font-size: 1.3rem;
//     line-height: 1.5;
//     margin: 20px auto 30px;
//     max-width: 640px;
//     font-weight: bold;
// }

// button {
//     -webkit-appearance: none;
//             appearance: none;
//     -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
//             filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
//     -webkit-transition: all .5s ease;
//            filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
//     -webkit-transition: all .5s ease;
//             transition: all .5s ease;
//     border: none;
//     background: #FFD800;
//     border-radius: 30px;
//     text-transform: uppercase;
//     box-sizing: border-box;
//     padding: 15px 40px;
//     font-weight: 400;
//     font-size: 13px;
//     cursor: cursor;
//   }

//   button:hover {
//     color: #FFFFFF;
//     background: #222222;
//     -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
//             filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
//   }


// .imageDiv {
//     background: no-repeat center center;
//     width: 100%;
//     background-size: cover;
// }

// // .center{
// //     height: 800px;
// // }

// .buttonDiv {
//     margin-top: 250px;
// }



h1 {
    font-size: 3em;
    font-weight: bold;
}

// .donateContainer{
//     display: flex;
//     // flex-direction: column;
//     background: #231f20;
//     color: white;
//     padding: 2.5rem;
//     // padding: 25px 75px 0 75px;
//     margin-top: 2rem;
//     border: 7px solid white;
// }
// .donateContainer{
//     display: flex;
//     // flex-direction: column;
//     background: #231f20;
//     color: white;
//     padding: 5rem;
//     //padding: 25px 75px 0 75px;
//     margin-top: 2rem;
//     border: 7px solid white;
// }



// .donationImageSection{
//     display: flex;
//     flex-direction: column;
//     min-width: 550px
//     // background: powderblue;
//     // width: 500px;
//     // margin: 0 6.4rem 0 0;
// }

.imageNav{
    width: 100%;
    overflow: hidden;
    // display: -webkit-box;
    // display: -ms-flexbox;
    display: flex;
    // -ms-flex-wrap: wrap;
    flex-wrap: wrap;
   
}

// .imageNav{
//     width: 75%;
//     overflow: hidden;
//     // display: -webkit-box;
//     // display: -ms-flexbox;
//     display: flex;
//     flex-direction: column;
//     // -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
   
// }

.tabNav{
    transition: filter .3s linear;
    width: 50%;
    list-style: none;
}

// .tabNav{
//     display: flex;
//     flex-direction: column;
//     transition: filter .3s linear;
//     width: 100%;
//     height: 50px;
//     list-style: none;
// }

a {
    color: inherit;
    text-decoration: none;
}

.title {
    color: black;
    font-weight: bold;
}

.title2{
    // left: 57%;
    // top: 59%;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 5px 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end; 
    position: block;
    align-items: center;
    flex-wrap: nowrap;
    
}

.imgHolder {
    display: block;
    background-size: contain;
    background-blend-mode: multiply;
    filter: contrast(70%) brightness(.75) saturate(2);
    padding-bottom: 100%;
    position: relative;
    background-size: cover;

    &:hover{
          filter: inherit
          }
    
}

