
.App {
  text-align: center;
}

// h1{
//   font-size: 3rem;
//   color: white;
// }

body {
  background: url("./images/abstract-cake.jpg") no-repeat center center;
  background-size: cover;
  // background: #e6e6fa;
  // color: #864313;
  font-size: 12px;
  }
.container {
  max-width: 1200px;
  width: 100%;
  display: flex;
  position: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
  // padding-top: 100px;
  margin-right: auto;
  margin-left: auto;
}


.threePanelSection {
  display: flex;
  justify-content: center;
  width: 100%;
}

.infoDiv{
  @media (max-width: 600px) {
    width: 100%;
  }
}


// .donateDiv {
//   display: flex;
//   justify-content: center;
//   width: 100%;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }

// css for the cake
@-webkit-keyframes flames {
  0% {
  height: 2.2em;
  -webkit-transform: rotate(0.5deg);
  transform: rotate(0.5deg);
  opacity: 0.8;
  }
  100% {
  height: 2.5em;
  -webkit-transform: rotate(-0.5deg);
  transform: rotate(-0.5deg);
  border-radius: 50% / 30%;
  border-top-right-radius: 50% 60%;
  border-top-left-radius: 50% 60%;
  opacity: 1;
  }
  }
  @keyframes flames {
  0% {
  height: 2.2em;
  -webkit-transform: rotate(0.5deg);
  transform: rotate(0.5deg);
  opacity: 0.8;
  }
  100% {
  height: 2.5em;
  -webkit-transform: rotate(-0.5deg);
  transform: rotate(-0.5deg);
  border-radius: 50% / 30%;
  border-top-right-radius: 50% 60%;
  border-top-left-radius: 50% 60%;
  opacity: 1;
  }
  }
  @-webkit-keyframes candles {
  0% {
  height: 10em;
  border-radius: 50% / 5%;
  }
  100% {
  height: 2em;
  border-radius: 50% / 25%;
  }
  }
  @keyframes candles {
  0% {
  height: 10em;
  border-radius: 50% / 5%;
  }
  100% {
  height: 2em;
  border-radius: 50% / 25%;
  }
  }
  *,
  *::before,
  *::after {
  box-sizing: border-box;
  }

  .title {
  text-align: center;
  font-size: 3em;
  }
  
  .cake {
  height: 30em;
  width: 30em;
  margin: 10em auto 0;
  position: relative;
  border-radius: 100% / 50%;
  background: #7b3d11;
  border-bottom: 0.25em solid #2d1606;
    @media (mad-width: 500px) {
      width: 25em;
    }
    @media (max-width: 400px) {
      display: none;
    }
    @media (max-width: 350px) {
      display: none;
    }
  }
  .cake::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15em;
  border-radius: 50%;
  background: #7b3d79;
  border-bottom: 0.5em solid #221105;
  z-index: 10;
  }
  .cake::after {
  content: '';
  position: absolute;
  top: 5em;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15em;
  border-radius: 50%;
  border-top-right-radius: 25%;
  border-top-left-radius: 25%;
  background: #86437f;
  box-shadow: 0 5em 0 #fff, 0 10em 0 #86437e;;
  }
  .candles {
  z-index: 20;
  position: absolute;
  padding: 0;
  margin: 0;
  height: 10em;
  width: 2.5em;
  position: absolute;
  bottom: 22.5em;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50% / 5%;
  // -webkit-animation: candles 10s 2s 2 alternate;
  // animation: candles 10s 2s 2 alternate;
  background: #ffcc00;
  box-shadow: -3.5em 5em 0 0 #77DD77, 3.5em 5em 0 0 #FF6961, -7em -2.5em 0 -3px #FFB347, 7em -2.5em 0 -3px #779ECB, -10.5em 2em 0 -1px #B19CD9, 10.5em 2em 0 -1px #A5D3CA;
  }
  .candles::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  border-radius: 50%;
  background: #cca300;
  box-shadow: -3.5em 5em 0 0 #4ed34e, 3.5em 5em 0 0 #ff392e, -7em -2.5em 0 -3px #ff9e14, 7em -2.5em 0 -3px #5284bd, -10.5em 2em 0 -1px #9477cb, 10.5em 2em 0 -1px #83c2b6;
  }
  .candles::after {
  content: '';
  position: absolute;
  top: -2em;
  right: 0;
  left: 0;
  margin: auto;
  border-radius: 50% / 40%;
  border-top-right-radius: 50% 60%;
  border-top-left-radius: 50% 60%;
  background: #E29822;
  height: 2.5em;
  width: 1.5em;
  -webkit-animation: flames 0.75s infinite alternate;
  animation: flames 0.75s infinite alternate;
  box-shadow: 0 0 0 1px #e8ad4f, -3.5em 5em 1px 0 #E29822, 3.5em 5em 1px 0 #E29822, -7em -2.5em 1px 0 #E29822, 7em -2.5em 1px 0 #E29822, -10.5em 2em 1px 0 #E29822, 10.5em 2em 1px 0 #E29822;
  }





  @keyframes yourAnimation {
    0.0%{
        font-size: 3rem;
        color: white;
    }
    20.1%{
        transform: scale(1);
        font-size: 3rem;
        color: white;
    }
    40.3%{
        transform: scale(1.35);
        font-size: 3rem;
        color: white;
    }
    60.1%{
        transform: scale(1.15);
        font-size: 3rem;
        color: white;
    }
    79.6%{
        transform: scale(1.35);
        font-size: 3rem;
        color: white;
    }
    99.5%{
        transform: scale(1);
        font-size: 3rem;
        color: white;
    }
  }

  @keyframes yourAnimationMobile {
    0.0%{
        font-size: 1.5rem;
        color: white;
    }
    20.1%{
        transform: scale(1);
        font-size: 1.5rem;
        color: white;
    }
    40.3%{
        transform: scale(1.35);
        font-size: 1.5rem;
        color: white;
    }
    60.1%{
        transform: scale(1.15);
        font-size: 1.5rem;
        color: white;
    }
    79.6%{
        transform: scale(1.35);
        font-size: 1.5rem;
        color: white;
    }
    99.5%{
        transform: scale(1);
        font-size: 1.5rem;
        color: white;
    }
  }

/* Add the animation: property to whichever element you want to animate */
// #elementToAnimate{
//   animation: yourAnimation 5s ease 0s infinite normal forwards;
// }

/* Add the animation: property to whichever element you want to animate */
.animateTitle{
  animation: yourAnimation 5s ease 2s infinite normal forwards;
  font-size: 3rem;
  color: white;
  margin: 33px 0;
  @media (max-width: 600px) {
    display: none;
}
}

.animateTitleMobile{
  display: none;
  @media (max-width: 600px) {
  display: block;
  animation: yourAnimationMobile 5s ease 2s infinite normal forwards;
  font-size: 1.5rem;
  color: white;
  margin: 33px 0;
  }
}

.targetElement{
  color:var(--red);
  border-radius: 50%;
  height: 300px;
  width: 500px;
  font-size: 65px;
  transform-origin: center center;    
}