
.infoContainer{
    display: flex;
    flex-direction: column;
    background: #231f20;
    color: white;
    padding: 2.5rem;
    // padding: 25px 75px 0 75px;
    margin-top: 2rem;
    border: 7px solid #ffffff;
    width: 100%;
    height: 100%;
    // position: absolute;
    // overflow: hidden;
}

.infoSection {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: -15px;
  margin-right: 10px;
  // padding-right: 25px;
  @media (max-width: 1024px) {
      padding-right: 0;
      margin-right: inherit;
  }
}

.infoImage {
  height: 250px;
  width: 250px;
  background: lavender;
  position: relative;
  display: flex;
  border-radius: 50%;
  flex: 0 0 203px;
//   margin-top: -30px;
  margin-bottom: 2rem;
  align-self: center;
}

.infoContent {
  text-align: justify;
  font-size: 1.5rem;
  padding-bottom: 1.5rem;
  @media (max-width: 1024px) {
      padding-right: inherit;
      text-align: left;
  }
}

h2 {
  font-size: 2rem;
}

p {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 1.5;
    margin: 20px auto 30px;
    max-width: 640px;
}

button {
//     -webkit-appearance: none;
//             appearance: none;
//     -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
//             filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
//     -webkit-transition: all .5s ease;
//            filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
//     -webkit-transition: all .5s ease;
//             transition: all .5s ease;
//     border: none;
//     background: #FFD800;
//     border-radius: 30px;
//     text-transform: uppercase;
//     box-sizing: border-box;
//     padding: 15px 40px;
//     font-weight: 400;
//     font-size: 13px;
//     cursor: cursor;
  }

  button:hover {
    color: black;
    background: white;
    -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
            filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  }
