
.footerDiv {
display: flex;
flex-direction: column;
justify-content: center;
position: relative;
// margin-top: -15px;
padding: 25px 0 25px 0;
width: 100%;
// height: 50px;
font-size: 1rem;
//background: #ffffd4; // temp color until footer is finalized.
background: lavender; 
border-top: 5px solid rgb(54, 52, 52);

}

.jamarDiv {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    display:inline-block;
}

.jamarSite {
    font-weight: bold;
    cursor: pointer;
}

.flaticonSite {
    cursor: pointer;
}